//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import packageItem from "./packageItem";
import { packageItemArr } from "../constants/packages.const";
import EventBus from '../event-bus';
export default {
  name: "packageComponent",

  components: {
    packageItem
  },

  props: {
    aPackage: Object,
    parentService: Object
  },

  methods: {
    goToPackagesPage() {
        if(this.$route.meta === 'homeUI'){
            this.$router.push("/packages");
        } else {
            EventBus.$emit('BOTTOM_SCROLL');
        }
    }
  }
};
