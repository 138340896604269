//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import serviceComponent from "./serviceComponent";
import packageComponent from './packageComponent';
import { packagePageArr } from '../constants/packages.const';
import i18n from '../plugins/i18n';

export default {
    name: 'PackagesPage',

    components: {
        serviceComponent,
        packageComponent
    },

    data() {
        return {
            packagesPageTitle: this.$t('packagesPageTitle'),
            packagesPageCopy: this.$t('packagesPageCopy'),
            packagesPageIllustration: require('../assets/img/packagesPageIllustration.svg'),
            packageItems: packagePageArr
        }
    }
}
