//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "sectionTitleComponent",

    props: {
        heading: String,
        titleAlignLeft: Boolean
    }
};
