//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import router from '.././main'
import sectionTitleComponent from './sectionTitleComponent'

export default {
    name: "serviceComponent",

    components: {
        sectionTitleComponent
    },

    props: {
        title: String,
        copy: String,
        illustration: String,
        alternative: String,
        isEven: Boolean,
        id: String
    },

    methods: {
        navigateToServicePage() {
            this.$router.push(`/service/${this.id}`);
        }
    }
};
