//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "packageItem",

    props: {
        packageItem: String
    }
};
