export const packageItemArr = [
    {   id: 'breakthrough', title: 'Breakthrough', description: 'breakthroughDesc',
        items: ['breakthroughItem1', 'breakthroughItem2', 'breakthroughItem3'] },
    
    {   id: 'eureka', title: 'Eureka', description: 'eurekaDesc',
        items: ['eurekaItem1', 'eurekaItem2', 'eurekaItem3', 'eurekaItem4', 'eurekaItem5', 'eurekaItem6'] },
    
    {   id: 'custom', title: 'Custom', description: 'customDesc',
        items: ['customItem'] },
]

export const packagePageArr = [
    { 
        id: 'breakthroughPP',
        title: 'Breakthrough',
        description: 'breakthroughPPDesc',
        items: ['breakthroughPPItem1', 'breakthroughPPItem2', 'breakthroughPPItem3', 'breakthroughPPItem4', 'breakthroughPPItem5', 'breakthroughPPItem6', 'breakthroughPPItem7'],
        itemsCol2: ['breakthroughPPCol2Item1', 'breakthroughPPCol2Item2', 'breakthroughPPCol2Item3', 'breakthroughPPCol2Item4', 'breakthroughPPCol2Item5', 'breakthroughPPCol2Item6', 'breakthroughPPCol2Item7']
    },
    
    { 
        id: 'eurekaPP',
        title: 'Eureka',
        description: 'eurekaPPDesc',
        items: ['eurekaPPItem1', 'eurekaPPItem2', 'eurekaPPItem3', 'eurekaPPItem4', 'eurekaPPItem5', 'eurekaPPItem6', 'eurekaPPItem7', 'eurekaPPItem8', 'eurekaPPItem9', 'eurekaPPItem10', 'eurekaPPItem11', 'eurekaPPItem12', 'eurekaPPItem13', 'eurekaPPItem14', 'eurekaPPItem15', 'eurekaPPItem16', 'eurekaPPItem17', 'eurekaPPItem18'],
        itemsCol2: ['eurekaPPCol2Item1', 'eurekaPPCol2Item2', 'eurekaPPCol2Item3', 'eurekaPPCol2Item4', 'eurekaPPCol2Item5', 'eurekaPPCol2Item6', 'eurekaPPCol2Item7', 'eurekaPPCol2Item8', 'eurekaPPCol2Item9', 'eurekaPPCol2Item10', 'eurekaPPCol2Item11', 'eurekaPPCol2Item12', 'eurekaPPCol2Item13', 'eurekaPPCol2Item14', 'eurekaPPCol2Item15', 'eurekaPPCol2Item16', 'eurekaPPCol2Item17', 'eurekaPPCol2Item18'] 
    },

    { 
        id: 'customPP',
        title: 'Custom',
        description: 'customPPDesc',
        items: '',
        itemsCol2: ''
    },
]